import React, { useContext, useState, Suspense } from "react";
import {
  BrowserRouter as Router,
  // Route,
  NavLink,
  // Redirect,
  // Switch,
} from "react-router-dom";
import "./App.css";
import { globalContext, GlobalContextProvider } from "./Context.js";
import {
  getLocalContent,
  showInfoDialog,
  // fetchWithRetry,
  // verifyUser,
  // sleep,
} from "./utils.js";
// import { LanguageBar } from "./Navigation.js";
// import logoEn from "./assets/images/logoen.webp";
// import logoBg from "./assets/images/logobg.webp";
// import logoEvent from "./assets/images/logoEvent.webp";
// import logoBeauty from "./assets/images/logoBeauty.webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAt,
  faEnvelope,
  // faSignInAlt,
} from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faInstagram, faLinkedinIn } from "@fortawesome/free-brands-svg-icons";

const Modal = React.lazy(() => import("./Modal.js"));
const WeddingCafe = React.lazy(() => import("./WeddingCafe.js"));
const EventCafe = React.lazy(() => import("./EventCafe.js"));
const BeautyCafe = React.lazy(() => import("./BeautyCafe.js"));

function showTermsAndConditions(dispatch, state) {
  import("./terms-and-conditions.js").then((component) => {
    component =
      state.language === "en" ? component.default.en : component.default.bg;
    showInfoDialog(
      dispatch,
      <span className="text-red">
        {getLocalContent(
          state.language,
          "Terms & Conditions",
          "Условия за ползване"
        )}
      </span>,
      component,
      true
    );
  });
}

function showCorporateInformation(dispatch, state) {
  import("./corporate-information.js").then((component) => {
    component =
      state.language === "en" ? component.default.en : component.default.bg;
    showInfoDialog(
      dispatch,
      <span className="text-red">
        {getLocalContent(
          state.language,
          "Corporate Information",
          "Корпоративна информация"
        )}
      </span>,
      component,
      true
    );
  });
}

function showPrivacyPolicy(dispatch, state) {
  import("./privacy-policy.js").then((component) => {
    showInfoDialog(
      dispatch,
      <span className="text-red">
        {getLocalContent(
          state.language,
          "Privacy Policy",
          "Политика за личните данни"
        )}
      </span>,
      component.default,
      true
    );
  });
}

const SITE_TO_USE = "c";

export const useLangPath = () => {
  let { state } = useContext(globalContext);
  return (path) => `/${state.language}${path}`;
};

const MainPage = (props) => {
  const { state, dispatch } = useContext(globalContext);
  const [site] = useState(SITE_TO_USE);
  const langPath = useLangPath();
  return (
    <Router>
      <div id="mainPage">
        {site === "w" && (
          <Suspense
            fallback={
              <div className="container page bg-ash-pink rounded blink" />
            }
          >
            <WeddingCafe />
          </Suspense>
        )}
        {site === "c" && (
          <Suspense
            fallback={
              <div className="container page bg-ash-pink rounded blink" />
            }
          >
            <EventCafe />
          </Suspense>
        )}
        {site === "b" && (
          <Suspense
            fallback={
              <div className="container page bg-ash-pink rounded blink" />
            }
          >
            <BeautyCafe />
          </Suspense>
        )}

        <footer className="position-relative footer text-red-light text-center mt-auto py-2">
          <div className="container ">
            <div className="large">
              <a
                href={
                  site === "w"
                    ? "https://www.facebook.com/SVATBENOCAFE"
                    : site === "c"
                    ? "https://m.facebook.com/eventcafeboutique"
                    : "https://www.facebook.com/beautycafeboutiquesofia/"
                }
                rel="noopener noreferrer"
                target="_blank"
                className="text-red d-inline-block px-2"
                onClick={(e) => {
                  document.activeElement.blur();
                }}
              >
                <FontAwesomeIcon icon={faFacebook} />
              </a>
              <a
                href={
                  site === "c"
                    ? "https://www.instagram.com/eventcafeboutique/"
                    : "https://www.instagram.com/weddingcafeboutique/"
                }
                rel="noopener noreferrer"
                target="_blank"
                className="text-red d-inline-block px-2"
                onClick={(e) => {
                  document.activeElement.blur();
                }}
              >
                <FontAwesomeIcon icon={faInstagram} />
              </a>
              {site === "c" && (
                <a
                  href="https://www.linkedin.com/company/event-cafe-boutique/"
                  rel="noopener noreferrer"
                  target="_blank"
                  className="text-red d-inline-block px-2"
                  onClick={(e) => {
                    document.activeElement.blur();
                  }}
                >
                  <FontAwesomeIcon icon={faLinkedinIn} />
                </a>
              )}
            </div>
            <NavLink
              className="text-red"
              as={NavLink}
              to={langPath("/contact")}
              exact
              activeClassName="active"
              onClick={(e) => {
                document.activeElement.blur();
                window.scrollTo(0, 0);
              }}
            >
              <FontAwesomeIcon icon={faEnvelope} /> office
              <FontAwesomeIcon icon={faAt} />
              eventcafeboutique.com
            </NavLink>
            <div className="small">
              <a
                href="#/"
                className="text-red d-inline-block mx-2"
                style={{ minWidth: "20px" }}
                onClick={(e) => {
                  e.preventDefault();
                  document.activeElement.blur();
                  showTermsAndConditions(dispatch, state);
                }}
              >
                {getLocalContent(
                  state.language,
                  "Terms & Conditions",
                  "Условия за ползване"
                )}
              </a>
              <a
                href="#/"
                className="text-red d-inline-block mx-2"
                style={{ minWidth: "20px" }}
                onClick={(e) => {
                  e.preventDefault();
                  document.activeElement.blur();
                  showPrivacyPolicy(dispatch, state);
                }}
              >
                {getLocalContent(
                  state.language,
                  "Privacy Policy",
                  "Политика за личните данни"
                )}
              </a>
              <a
                href="#/"
                className="text-red d-inline-block mx-2"
                style={{ minWidth: "20px" }}
                onClick={(e) => {
                  e.preventDefault();
                  document.activeElement.blur();
                  showCorporateInformation(dispatch, state);
                }}
              >
                {getLocalContent(
                  state.language,
                  "Corporate Information",
                  "Корпоративна информация"
                )}
              </a>
            </div>
            <div className="small">
              Event Cafe Boutique &copy; 2010-{new Date().getFullYear()} All
              rights reserved.
            </div>

            <div className="developed-by small font-italic">
              Developed by{" "}
              <a
                href="https://www.insentico.com"
                rel="noopener noreferrer"
                target="_blank"
                className="text-red"
              >
                Insentico Ltd.
              </a>
            </div>
            <div className="ads small font-italic d-none">
              <a
                href="https://www.easycryptobot.com"
                rel="noopener noreferrer"
                target="_blank"
                className="text-red"
              >
                easycryptobot
              </a>
            </div>
          </div>
        </footer>
      </div>
      <Suspense
        fallback={
          <div className="container page bg-ash-pink rounded h-100 mr-2 blink" />
        }
      >
        <Modal
          id="infoDialog"
          header={state.infoDialog.header}
          content={state.infoDialog.content}
          large={state.infoDialog.large}
          closeFn={state.infoDialog.closeFn}
        />
      </Suspense>
      {/* <ModalConfirm
        id={"confirmDialog"}
        header={state.confirmDialog.header}
        content={state.confirmDialog.content}
        func={() => {
          if (state.confirmDialog.func()) {
            state.confirmDialog.func();
          }
        }}
        large={state.confirmDialog.large}
      /> */}
    </Router>
  );
};

const App = (props) => {
  return (
    <GlobalContextProvider>
      <MainPage />
    </GlobalContextProvider>
  );
};

export default App;
